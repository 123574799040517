import {useState, useEffect} from "react";
import {app, database} from "./firebase";

const {orderByChild, ref, equalTo, query, get} = require("firebase/database")

export function useCommunities(userId) {
    const [communities, setCommunities] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");

    const queryRef = ref(database, 'chats');

    const chatQuery = query(queryRef, orderByChild('isGroupChat'), equalTo(true));


    useEffect(
        function () {
            const controller = new AbortController();

            async function fetchCommunities() {
                try {
                    console.log(" chatQuery", chatQuery);
                    let data = '';

                    await get(chatQuery)
                        .then((snapshot) => {
                            data = snapshot.toJSON();
                            console.log('snapshot', snapshot.toJSON());
                            return data;
                        })
                        .catch((error) => {
                            console.error('Error fetching data:', error);
                        });

                    // console.log(' data ', data);

                    // return data;

                    // const data = {
                    //     "-NwiX5AKzWvZkfbdq_PX": {
                    //         "chatName": "new Name",
                    //             "createdAt": "2024-04-30T10:24:01.151Z",
                    //             "createdBy": "lZ8Og8uzxMTpfBiZcdAGfr2ff1r1",
                    //             "isGroupChat": false,
                    //             "latestMessageText": "Really there is a meeingt",
                    //             "updatedAt": "2024-04-30T14:07:00.145Z",
                    //             "updatedBy": "tShk7dqK3ogp4USoHJi7dR1NKFJ3",
                    //             "users": [
                    //             "tShk7dqK3ogp4USoHJi7dR1NKFJ3",
                    //             "lZ8Og8uzxMTpfBiZcdAGfr2ff1r1"
                    //         ]
                    //     },
                    //     "-NwjOOdb8NonWMMfzWcp": {
                    //         "chatName": "All the Els",
                    //             "createdAt": "2024-04-30T14:25:38.793Z",
                    //             "createdBy": "lZ8Og8uzxMTpfBiZcdAGfr2ff1r1",
                    //             "isGroupChat": true,
                    //             "latestMessageText": "It’s beautiful",
                    //             "updatedAt": "2024-05-05T20:36:40.782Z",
                    //             "updatedBy": "lZ8Og8uzxMTpfBiZcdAGfr2ff1r1",
                    //             "users": [
                    //             "Q5LltgOUlha0tnsirjjR5swegki1",
                    //             "tShk7dqK3ogp4USoHJi7dR1NKFJ3",
                    //             "lZ8Og8uzxMTpfBiZcdAGfr2ff1r1"
                    //         ]
                    //     },
                    //     "-NwjOpn4y3-HTBwtoqo9": {
                    //         "chatName": "ask Jill",
                    //             "createdAt": "2024-04-30T14:27:34.087Z",
                    //             "createdBy": "lZ8Og8uzxMTpfBiZcdAGfr2ff1r1",
                    //             "isGroupChat": false,
                    //             "latestMessageText": "Hi",
                    //             "updatedAt": "2024-04-30T22:47:31.381Z",
                    //             "updatedBy": "lZ8Og8uzxMTpfBiZcdAGfr2ff1r1",
                    //             "users": [
                    //             "Q5LltgOUlha0tnsirjjR5swegki1",
                    //             "lZ8Og8uzxMTpfBiZcdAGfr2ff1r1"
                    //         ]
                    //     },
                    //     "-NwjP7bYBNtvkN6e9jFZ": {
                    //         "chatName": "ask Jill",
                    //             "createdAt": "2024-04-30T14:28:51.174Z",
                    //             "createdBy": "lZ8Og8uzxMTpfBiZcdAGfr2ff1r1",
                    //             "isGroupChat": false,
                    //             "latestMessageText": "Sure, 90s music is often celebrated for its diversity, spanning various genres and styles. This decade saw the rise and fall of grunge with bands like Nirvana and Pearl Jam, the mainstream popularity of hip hop and R&B with artists like Tupac Shakur, Notorious B.I.G., Mariah Carey, and Whitney Houston, and the emergence of pop superstars such as Britney Spears and the Backstreet Boys. Alternative rock also found a huge audience with bands like Radiohead, Red Hot Chili Peppers, and Oasis. What aspect of 90s music interests you most?",
                    //             "updatedAt": "2024-05-05T23:06:28.326Z",
                    //             "updatedBy": "qsEJwm1elWfyRGdaSv5aFcQc1qQ2",
                    //             "users": [
                    //             "qsEJwm1elWfyRGdaSv5aFcQc1qQ2",
                    //             "lZ8Og8uzxMTpfBiZcdAGfr2ff1r1"
                    //         ]
                    //     },
                    //     "-NwqbhNYUVxLVHucnKXt": {
                    //         "chatName": "No Joe",
                    //             "createdAt": "2024-05-02T00:05:30.253Z",
                    //             "createdBy": "Q5LltgOUlha0tnsirjjR5swegki1",
                    //             "isGroupChat": true,
                    //             "latestMessageText": "Hi",
                    //             "updatedAt": "2024-05-02T00:05:30.563Z",
                    //             "updatedBy": "Q5LltgOUlha0tnsirjjR5swegki1",
                    //             "users": [
                    //             "tShk7dqK3ogp4USoHJi7dR1NKFJ3",
                    //             "Q5LltgOUlha0tnsirjjR5swegki1"
                    //         ]
                    //     }
                    // };

                    let finalData = [];

                    for (const key of Object.keys(data)) {
                        if(Object.values(data[key].users).includes(userId)){
                            let founder = false;
                            if(data[key].createdBy === userId)
                            {
                                founder = true;
                            }
                            finalData.push({"id": key, "name": data[key].chatName, "founder":  founder });
                        }
                    }


                    if (data.Response === "False") throw new Error("community not found");

                    setCommunities(finalData);
                    setError("");
                } catch (err) {
                    if (err.name !== "AbortError") {
                        console.log(err.message);
                        setError(err.message);
                    }
                } finally {
                    setIsLoading(false);
                }
            }

            fetchCommunities();

            return function () {
                controller.abort();
            };
        },
        [userId]
    );

    return {communities, isLoading, error};
}
